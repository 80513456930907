<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
            <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

            </v-breadcrumbs>
            </div>
        </v-row><br><br>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">
                <span>Book Classification</span>
            </v-card-title>
            <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
            <v-card-text>
                <div class="add-section">
                    <div style="text-align: center;">
                        <v-col>
                            <v-form ref="form">
                                <label class="add-text">Add New Book Classification</label><span style="color: red;"> *</span>
                                <!-- <v-text-field class="text" v-model="userType" outlined dense :rules="[rules.required]" clearable required></v-text-field> -->

                                <v-text-field class="text" v-model="bookClassification" outlined dense
                                    :rules="[rules.required]" clearable required></v-text-field>
                                <v-card-text style="padding-bottom: 0px;">
                                    <label class="add-text">Display Order</label><span style="color: red;"> *</span>
                                    <v-text-field class="text" v-model="sort_order" outlined dense :rules="[rules.required]"
                                    type="number" :min="0" required></v-text-field>
                                </v-card-text>

                                <v-btn color="success" @click="addBookClassification()">Add</v-btn>
                            </v-form>
                        </v-col>
                    </div>
                </div>

                <template>
                    <div class="text-center">
                        <v-dialog v-model="dialog" width="500">

                            <v-card>

                                <v-card-title class="grey lighten-2">
                                    Edit Book Classification
                                </v-card-title>

                                <v-card-text style="padding-bottom: 0px;">
                                    <label class="add-text">Book Classification</label><span style="color: red;"> *</span>
                                    <v-text-field class="text" v-model="editbookclassification" outlined dense
                                        :rules="[rules.required]" clearable required></v-text-field>
                                    <!-- <v-text-field class="text" v-model="editUserType" outlined dense :rules="[rules.required]" clearable required></v-text-field> -->


                                </v-card-text>
                                <v-card-text style="padding-bottom: 0px;">
                                    <label class="add-text">Display Order</label><span style="color: red;"> *</span>
                                    <v-text-field class="text" v-model="sort_order" outlined dense :rules="[rules.required]"
                                    type="number" :min="0" required></v-text-field>
                                </v-card-text>

                                <!-- <v-divider></v-divider> -->

                                <div class="text-center">
                                    <v-spacer></v-spacer>
                                    <v-btn class="m-3" @click="dialog = false">
                                        Close
                                    </v-btn>
                                    <v-btn class="m-3" @click="saveEdit(item)">Update</v-btn>
                                </div>
                            </v-card>
                        </v-dialog>
                    </div>
                </template>
                <div v-if="loader">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
                <template v-if="bookClassificationList != null">
                    <v-data-table :headers="headers" :items="bookClassificationList" :search="search"
                        class="elevation-1 nowrap">
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line
                                    hide-details></v-text-field>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.edit="{ item }">
                            <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
                            <v-icon v-if="item" class="mr-2" @click="updateBookClassification(item)">mdi
                                mdi-pencil-box</v-icon>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <!-- <v-btn :loading="edit_btn_load" @click="editItem(item)" class="link" color="info"> <v-icon title="Activate/Deactivate the User Type">mdi-recycle</v-icon></v-btn> -->
                            <v-switch v-model="item.Isactive" @change="editItem(item)"></v-switch>
                        </template>
                        <template v-slot:item.delete="{ item }">
                            <v-icon v-if="item" class="mr-2" @click="deletebookclassification(item)"> mdi
                                mdi-delete</v-icon>
                        </template>
                    </v-data-table>
                </template>
                </div>
            </v-card-text>
            </div>
        </v-card>
        <!-- <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay> -->
    </div>
</template>


<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
    data: () => ({
        snackbar_msg: "",
        init_loading: false,
        snackbar: false,
        loader: false,
        color: "",
        org: null,
        dialog: false,
        search: "",
        bookClassification: null,
        sort_order: null,
        edit_btn_load: false,
        bookclassificationId: null,
        editbookclassification: null,
        editbookclassificationId: null,
        bookclassificationSA: null,
        items: [
            {
                text: 'Dashboard',
                disabled: false,
                href: '/home',
            },
            {
                text: localStorage.getItem("jhhjbyj"),
                disabled: false,
                href: '/sublink',
            },
            {
                text: 'Admin Master',
                disabled: false,
                href: '/admin-master',
            },
            {
                text: 'Book Classification',
                disabled: true,
                href: '',
            },
        ],
        bookClassificationList: [],
        Isactive: "",
        rules: {
            required: (value) => !!value || "Required.",
        },

        headers: [
            { text: "Sr no", value: "srno" },
            { text: "Name", value: "name" },
            { text: "Display Order", value: "sort_order" },

            { text: "Edit", value: "edit" },
            { text: "Toggle", value: "actions", sortable: false },
            { text: "Delete", value: "delete" },
        ],
    }),
    watch: {},
    mounted() {
        this.onload();
    },
    methods: {
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;

        },

        addBookClassification() {
            //alert("in save"+this.sort_order);
            if(!this.bookClassification){
                this.showSnackbar("#b71c1c", "Please Enter Book Classification !!!");
                return;
            }
            else if(!this.sort_order){
                this.showSnackbar("#b71c1c", "Please Enter Display Order !!!");
                return;
            }
            const data = {
                bookclassification: this.bookClassification,
                sort_order: this.sort_order

            };
            this.loader = true;
            axios
                .post("/Admin/savebookclassification", data)
                .then((res) => {
                    this.loader = false;
                    this.onload();
                    this.showSnackbar("#4CAF50", "book classification add successfully...");
                    this.$refs.form.reset()
                })
                .catch((error) => {
                    this.loader = false;
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.loader = false;
                    this.edit_btn_load = false;
                    axios;
                });
        },

        onload() {
            //  alert("in onload");
            this.init_loading = true
            axios
                .post("/admin/bookclassification")
                .then((res) => {
                    this.init_loading = false;
                    this.bookClassificationList = res.data.bookClassificationList;
                    //this.showSnackbar("#4CAF50","Success")
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.init_loading = false
                });
        },
        editItem(item) {
            const data = {
                Isactive: item.Isactive,
                Id: item.Id

            };

            axios
                .post("/admin/bookclassificationIsactive", data)
                .then((res) => {
                    if (res.data.msg == "200")
                        // this.sec_mode_list = res.data.user_list;
                        this.showSnackbar("#4CAF50", "Is active updated successfully...");
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.edit_btn_load = false;
                    axios;
                });
        },
        updateBookClassification(item) {
            //  alert(item.Id);
            this.dialog = true;
            //   this.bookClassificationList=res.data.bookClassificationList;
            this.editbookclassification = item.name;
            this.editbookclassificationId = item.Id;
            console.log(item.sort_order);
            this.sort_order = item.sort_order;

        },
        saveEdit(item) {
            // alert("updated"+this.editbookclassification+"id "+this.editbookclassificationId);
            console.log(item);
            if(!this.editbookclassification){
                this.showSnackbar("#b71c1c", "Please Enter Book Classification !!!");
                return;
            }
            else if(!this.sort_order){
                this.showSnackbar("#b71c1c", "Please Enter Display Order !!!");
                return;
            }
            const data = {
                name: this.editbookclassification,
                Id: this.editbookclassificationId,
                sort_order: this.sort_order,

            };

            this.loader = true;
            axios
                .post("/admin/editbookclassification", data)
                .then((res) => {
                    if (res.data.code == "200") {
                        this.loader = false;
                        //  this.bookClassificationList=res.data.bookClassificationList;

                        this.showSnackbar("#4CAF50", "Book Classification updated successfully...");

                        this.dialog = false;
                        this.onload();
                    } else {
                        this.showSnackbar("#4CAF50", res.data.msg);
                    }
                })
                .catch((error) => {
                    this.loader = false;
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.loader = false;
                    this.edit_btn_load = false;
                    this.edit = null;
                    axios;
                });

        },
        deletebookclassification(item) {
            // alert(item.Id);
            const data = {
                // bookclassificationId: item.bookclassificationId,

                deleteitem: item.Id


            };
            axios
                .post("/admin/deletebookclassification", data)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.onload();
                        this.showSnackbar("#4CAF50", "Book Classification Deleted Successfully  !!");

                    } else if (res.data.msg == "exp") {
                        this.showSnackbar("#b71c1c", "Can not be Deleted!!!");
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    window.console.log(error);
                });

        },

    }
}
</script>
